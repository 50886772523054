import React, {  useEffect, useMemo, useState } from 'react';
import {  PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Button, ConfirmModal, Tooltip, useTheme2, Input } from '@grafana/ui';
import { Paper, TableContainer, Table, TableHead, ThemeProvider, createTheme, TableCell, TableRow, TableBody } from '@mui/material';

//, TextField } from '@mui/material';
interface Props extends PanelProps<SimpleOptions> {}

interface TableSolarTurnOff{
  id: number,
  groupname: string,
  settings: SettingsTurnOff ,
  done: boolean,
  created_at: number
  showModal: boolean;
}

interface SettingsTurnOff{
command: string,
deviceList: DeviceInfo[],
when: number
}
interface DeviceInfo{
  address: Number,
  name: string
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const theme = useTheme2();

  const [tableData_turnoff,setTableData_turnoff] = useState<TableSolarTurnOff[]>([]);
  const [update,setUpdate] = useState<Boolean>(false);
  //const [modal,setModal] = useState<boolean>(false);
  const [grafanaApiBaseUrl,_setGrafanaApiBaseUrl] = useState<string>(options.api);
  const [datasourceUID,_setDataSourceUID] = useState<string|undefined>(data.request?.targets[0].datasource?.uid);

  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource: {
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  
  //1. fetch data
  //const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';

const headers = useMemo(() => {
  // Replace the following with your actual header initialization logic
  return {
    //Authorization: 'Bearer your_access_token',
    'Content-Type': 'application/json',
  };
}, []);

const handleDelete_turnoff = async (row: any,index: number) => {
   
  
await doDelete(row.id);
const updated = [...tableData_turnoff];
updated.splice(index, 1);
setTableData_turnoff(updated);
//setUpdate(!update);
 // setErrors(updatedErrors);
};


const doDelete = async (id: number)=>{
  const deleteSql = `DELETE FROM turnoff WHERE id = ${id}`;
  const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
  await fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
  
}

useEffect(() => {
  // Function to handle the event
  const handleEvent = (event: Event) => {
    console.log("updateTurnOffList received" );
    // Your event handling logic here
    setUpdate(!update);
    //reloadTable();
  };

  // Add the event listener when the component mounts
  document.addEventListener('updateTurnOffList', handleEvent);

  // Remove the event listener when the component unmounts
  return () => {
    document.removeEventListener('updateTurnOffList', handleEvent);
  };
}, [update]); // Empty dependency array ensures this effect runs only once

useEffect(() => {
  console.log("use effect reload table...");
  //reloadTable();
  
  
  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource: {
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  
  //1. fetch data
  //const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  //const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

  //table select for vemat_devices 
  const sqlCommands = [`SELECT * FROM turnoff where done=false order by id asc`
];
  //const rawSql = `SELECT address,name,coef FROM vemat_devices order by id desc`;

  const query = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);

const headers =  {
  // Replace the following with your actual header initialization logic

    //Authorization: 'Bearer your_access_token',
    'Content-Type': 'application/json',
  };


  const reload = ()=>{
    fetchData(grafanaApiBaseUrl, method, headers, query).then(data => {
      if (data) {
        console.log("sj");
        if(data.results.A.frames.length>0){
          const frames = data.results.A.frames;
        //const mappedData = {};
        frames.forEach((frame: any) => {
          const { data: { values } } = frame;
          const ids = values[0];
          const groupname = values[1];
          const settings = values[2];
          const done = values[3];
          const created_at = values[4];
          let turnff_data = [];
        
          //let solaroff_data = [];
          for (let i = 0; i < ids.length; i++) {
            const settingType = JSON.parse(settings[i]);
          
            switch(settingType.command){
            case "timedoff":
            //turn of based on time
        
            break;
              
              case "quantityoff":
            //turnoff based on quantity 
          
  
              break;
  
              case "solaroff":
                //turnoff based on solar energy
                turnff_data.push({
                  id: ids[i],
                  groupname: groupname[i],
                  settings: settingType,
                  done: done[i],
                  created_at: created_at[i],
                  showModal: false
                });
              break;
  
                default:
                break;
            
          }
  
          }
          
         
          setTableData_turnoff(turnff_data);
  
        });
        
     
      }
      
        //napamovani nactenych dat do StateVariable
      }
      
    });
  
  };
  
  reload();
  return () => {
    // Cleanup code here
  };
}, [update,datasourceUID,grafanaApiBaseUrl]);

interface FlexContainerStyle {
  display: string;
  float: 'left' | 'right' | 'none'; // Specify the valid values for float
}

const flexContainerStyle: FlexContainerStyle = {
  display: 'flex',
  float: 'left' // Or 'right', or 'none' based on your requirement
};
const table_theme = createTheme(
  {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.text,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          //color: theme.colors.primary.contrastText,
          color: theme.colors.primary.text
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        head: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        root: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.text,
        },
      },
    },
  },
});

  return (    
    <ThemeProvider theme={table_theme}>
      <TableContainer 
        component={Paper} 
        style={{
          maxHeight: height, // Přidána dynamická výška podle parametru height
          overflowY: 'auto'  // Přidáno pro rolování obsahu
        }}
        elevation={0} // Nastaví elevation na 0, čímž odstraní stín
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Název skupiny</TableCell>
              <TableCell>Zařízení ve skupině</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData_turnoff.map((row, index) => {
              const deviceElements = [];

              for (let deviceIndex = 0; deviceIndex < row.settings.deviceList.length; deviceIndex++) {
                const device = row.settings.deviceList[deviceIndex].address;
                const deviceName = row.settings.deviceList[deviceIndex].name;
                const combinedDevice = `${device} - ${deviceName}`; // Combine device and deviceName

                deviceElements.push(
                  <Tooltip content={combinedDevice} key={deviceIndex}>
                    <div style={flexContainerStyle}>{device.toString()}&nbsp; </div>
                  </Tooltip>
                );
              }

              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Input
                      disabled={true}
                      value={row.groupname}
                      style={{ height: '50%' }}
                    />
                  </TableCell>

                  <TableCell>
                    {deviceElements}
                  </TableCell>
                  <TableCell>
                  <Button size="md" variant='destructive' onClick={()=>setTableData_turnoff(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: true } : device))} >Smazat</Button>
            <ConfirmModal isOpen={row.showModal}
            title={"Smazat skupinu"}
             body={"Opravdu chcete smazat skupinu?"}
              description={"Skupina bude smazána."} 
              confirmText={"Smazat"} confirmButtonVariant={"primary"}
               dismissText={"Zrušit"} icon={"exclamation-triangle"}
                onConfirm={()=>handleDelete_turnoff(row,index)} onDismiss={()=>setTableData_turnoff(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: false } : device))} />

                  
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div>
          <div>
          </div>
        </div>
      </TableContainer>
    </ThemeProvider>
  );
};
